<template>
  <div class="members-content content">
    <div class="content-search">
      <ui-input @input="searchList"  search :placeholder="$t('search')" v-model="search" />
      <div class="content-search__icons">
        <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy.value }]"
                  :key="key"
              >
                {{ item.text }}
                <img v-if="item.value === sortBy.value" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="updateList"
        @reset="resetSearch"
        :disabled="isPointsValid || isNumbersValid"
    >
      <ui-select
          v-model="searchObj.region"
          :label="$t('city-location')"
          :options="getRegions"
      />
    </search-filter>
    <div class="members-content-body content-body">
      <ui-loader v-model="visibleLoader" />
      <div v-if="!visibleLoader" class="">
        <p v-if="membersData.length " class="content-body__title">{{ $t('participants-open-length', {from: membersData.length, to: totalCount}) }}</p>
        <div v-if="membersData.length" class="content-body__cards">
          <members-card
              v-for="(item, key) in membersData"
              :data="item"
              :key="key"
              show-action
              :is-delete="$route.query.status === 'deleted'"
              :is-blocked="$route.query.status === 'blocked' || item.isBlocked"
              @updatePage="updateList"
          />
        </div>
        <div v-else class="empty">
          {{ $t('empty') }}
        </div>
      </div>
      <ui-button v-if="visibleLoadMore && total !== membersData.length" @click="loadMore"  color="white">
        {{ $t('show-more') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getUserRole } from "@/utils/user";
import { capitalize } from "@/utils/general";

export default {
  name: "WorkReqContent",
  components: {
    UiInput: () => import('@/components/ui/UiInput'),
    UiSelect: () => import('@/components/ui/UiSelect'),
    UiMenu: () => import('@/components/ui/UiMenu'),
    MembersCard: () => import('@/components/template/members/MembersCard'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiLoader: () => import('@/components/ui/UiLoader'),
    SearchFilter: () => import('@/components/common/SearchFilter'),
  },

  data() {
    return {
      getUserRole,
      totalCount: 0,
      search: '',
      sortBy: '',
      sortData: [
        { text: this.$t('by-full-name-nickname'), value: 'login' },
        // { text: 'По названию', value: 'star' },
        { text: this.$t('by-place-of-residence-location'), value: 'region' },
        { text: this.$t('by-number-of-requests-2'), value: 'number' }
      ],
      visibleFilter: false,
      visibleSort: false,
      visibleLoadMore: false,
      total: 0,
      visibleLoader: true,
      searchObj: {

      },
    }
  },

  watch: {
    $route() {
      this.$route.query.status === 'new' ? this.sortBy = {value: 'id'} : this.sortBy = ''
      this.updateList()
    },
    getUser: {
      immediate: true,
      handler(val) {
        if(val?.id) {
          this.getMembersList()
        }
      }
    }
  },

  computed: {
    ...mapGetters(['membersData', 'getUser', 'getRegions', "getMembersMeta"]),

    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },
  },



  methods: {
    ...mapActions(['users', 'reqRegions']),
    ...mapMutations(['setMembersData', "setMembersMeta"]),

    resetSearch() {
      this.searchObj = {}
      this.updateList(true)
    },

    searchList() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateList()
      }, 500)
    },

    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateList()
    },

    updateList(reset = false) {
      this.visibleLoader = true
      this.visibleFilter = reset
      const { status } = this.$route.query
      let roles = () => {
          if(!status || status === 'new') {
            return this.getUserRole(this.getUser.role).memberStatus
          }
          else if (status === 'deleted') {
            return null
          }
          else if (status === 'blocked') {
            return null
          }
          else if (status === 'rejected') {
            return this.getUserRole(this.getUser.role).memberStatus
          }
          else {
            return [capitalize(status)]
          }
      }
      this.users({
        roles: roles(),
        sort: this.sortBy?.value,
        take: 21,
        order: this.sortBy.value === 'number' || this.sortBy.value === 'id' ? 'DESC' : this.sortBy.value ? 'ASC' : null,
        search: this.search,
        regionId: this.searchObj.region?.id,
        status: status === 'new' ? 'New' : status === 'deleted' || status === 'blocked' ? null : status === 'rejected' ? 'Rejected' : 'Accepted',
        isDeleted: status === 'deleted' ? true : null,
        isBlocked: status === 'blocked' ? true : null
      }).then((res) => {
        this.visibleLoader = false
        this.setMembersData(res.data)
        this.totalCount = res.meta.total
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
      this.updateForCounter()
    },

    getMembersList() {
      const { status } = this.$route.query
      let roles = () => {
        if(!status || status === 'new') {
          return this.getUserRole(this.getUser.role).memberStatus
        }
        else if (status === 'deleted') {
          return null
        }
        else if (status === 'blocked') {
          return null
        }
        else if (status === 'rejected') {
          return this.getUserRole(this.getUser.role).memberStatus
        }
        else {
          return [capitalize(status)]
        }
      }
      this.users({
        roles: roles(),
        sort: this.sortBy?.value,
        take: 21,
        search: this.search,
        order: this.sortBy.value === 'number' || this.sortBy.value === 'id' ? 'DESC' : this.sortBy.value ? 'ASC' : null,
        skip: this.membersData.length,
        status: status === 'new' ? 'New' : status === 'deleted' || status === 'blocked' ? null : status === 'rejected' ? 'Rejected' : 'Accepted',
        isDeleted: status === 'deleted' ? true : null,
        isBlocked: status === 'blocked' ? true : null
      }).then((res) => {
        this.visibleLoader = false
        this.setMembersData(this.$store.state.usersStore.membersData.concat(res.data))
        this.totalCount = res.meta.total
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
      this.updateForCounter()
    },

    updateForCounter() {
      // const { status } = this.$route.query
      this.users({
        roles: this.getUserRole(this.getUser?.role).memberStatus,
        sort: this.sortBy?.value,
        take: 21,
        search: this.search,
        scoreFrom: this.searchObj.scoreFrom,
        scoreTo: this.searchObj.scoreTo,
        ordersFrom: this.searchObj.ordersFrom,
        ordersTo: this.searchObj.ordersTo,
        regionId: this.searchObj.region?.id,
        status: 'Accepted',
      }).then((res) => {
        this.visibleLoader = false
        this.setMembersMeta(res.meta)
      })
    },

    loadMore() {
      this.getMembersList()
    }
  },

  mounted() {
    this.reqRegions()
    this.$route.query.status === 'new' ? this.sortBy = {value: 'id'} : this.sortBy = ''
  },

  destroyed() {
    this.setMembersData([])
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  max-width: 1000px;
  width: 100%;

  @media(max-width: 1200px) {
    padding-left: 0;
    padding-top: 52px;
  }
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;

  @media (max-width: 992px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column;
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  gap: 50px;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
    margin-bottom: 20px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

</style>
